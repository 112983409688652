/* Sign in page styles */

.txt-bold {
  font-weight: bold;
}

.hero-font {
  font-family: 'Titania', sans-serif;
}

.rale-font {
  font-family: 'Raleway', sans-serif;                                              
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 20vh;
  padding: 2%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-body {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-logo {
  width: 85%;
}

/* styles to align with the website */

/* color definitions: 
$courant-blue: #3294D9;
$courant-black: #061222;
$courant-white: #D9D9D9;
$courant-red: #E74B60;
$courant-green: #18BCA4;
(blue from buttons and various things): #3371F2;
(light grey background): #ECF2FE;

text definitions:
$hero-font: Titania
$rale-font: Raleway
$general-font: Roboto */

body {
  box-sizing: border-box;
  color: #061222;
  font-family: 'Roboto', sans-serif;
  background-color: #FFFFFF;
  z-index: 1;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.content {
  padding: 2em;
}

.left-content {
  display: flex;
  justify-content: start;
}

.fill-gap {
  text-wrap: nowrap;
}

.white-out {
  z-index: 1;
  width: 65%;
  padding-left: 3px;
  background-color: #FFFFFF;
}

.f8-out {
  z-index: 1;
  background-color: #F8F8FF;
}

.input-space {
  margin-bottom: 15px;
}

.signin {
  position: relative;
  top: 4em;
  padding-top: 10%;
  z-index: 1;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #d9d9d9;
  font-size: .85rem;
  font-style: italic;
  padding-left: 1rem;

}
::-moz-placeholder { /* Firefox 19+ */
  color: #d9d9d9;
  font-size: .85rem;
  font-style: italic;
  padding-left: 1rem;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #d9d9d9;
  font-size: .85rem;
  font-style: italic;
  padding-left: 1rem;
}
:-moz-placeholder { /* Firefox 18- */
  color: #d9d9d9;
  font-size: .85rem;
  font-style: italic;
  padding-left: 1rem;
}

.sign-in-headline {
  margin-bottom: 1em;
}

.sign-in-input {
  width: 100%;
  height: 110%;
  border: 1px solid #000;
  border-radius: 4px;
  z-index: 1;
  margin-bottom: 1rem;
}

.sign-in-button-grp {
  margin-top: 2em;
  display: grid;
}

.sign-in-button {
  font-weight: bold;
  font-size: .85rem;
  margin-top: 0.5em;
  background-color: #FFF;
  color: black;
  border: 1.5px solid black;
  width: 120px;
  height: 44px;
  border-radius: 4px;
  justify-self: center !important;
}

.date {
  font-size: .65rem;
  font-weight: bold;
  padding: .5rem 0rem .5rem 0rem;
  font-family: 'Raleway', sans-serif; 
}

.headline {
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0rem 1rem 0rem;
  font-family: 'Raleway', sans-serif; 
}

.headline-sm {
  font-size: .75rem;
  font-weight: 900;
  padding-top: 1rem;
  font-family: 'Raleway', sans-serif; 
}

.lable-sm {
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  padding-left: 1rem;
  color: rgba(120, 120, 120, 0.4);
}

.card-text {
  font-size: .75rem;
  padding-bottom: 1rem;
}

.select-styled {
  background-color: #FFFFFF;
  /* color: gray; */
  border: 1px solid gray;
  border-radius: 2px;
}

.full-section {
  max-width: 100%;
}

.auth-page {
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.full-height {
  height: 100vh;
}

.news {
  padding-top: 8em;
  height: 100%;
  border-left: 1.5px solid #000;
}

.news-card {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 1em;
  text-align: start;
}

.cards {
  margin: 2em;
}

.h-link, .link, a { /* for now, have a separate headline link class, but keep both regular anchor and that class similarly styled; clean up when I know exactly how regular anchor tags need to be styled */
  text-decoration: none;
  color: #061222;
  cursor: pointer;
}

ul {
  list-style: none;
}

nav {
  padding-left: 2em;
  padding-bottom: 1em;
  height: 4em;
}

.nav {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f9f9f9;
  overflow: hidden;
  z-index: 100;
}

.news-img {
  margin-left: auto;
  width: fit-content;
  margin-right: auto;
  padding: 1.5rem;
}

.left-nav {
  display: flex;
  margin-bottom: auto;
}

.nav-logo {
  position: relative;
  margin-top: 0;
  top: 0;
  padding-top: 1em;
  padding-left: 2em;
  height: 4em;
}

.right-nav {
  display: flex;
  /* margin-top: -1.5em; this feels hacky, but easiest/quickest/(only? prob not only) way I could find to get this element lined up. */
}

.menu a, .menu span {
  display: block;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  color: black;
}

.menu a:hover, .menu span:hover {
  background-color: #85888C;
}

.nav-item {
  border-bottom: 0px;
  transition: border-bottom 0.3s linear 0s;
}

.nav-item:hover {
  border-bottom: 2.5px solid #808080 !important;
}

.nav {
  max-height: 0;
  transition: max-height .1s ease-out;
}

.hamb {
  cursor: pointer;
  float: right;
  padding-top: 30px;
  padding-right: 80px;
  padding-left: 80px;
  height: 4em;
}

.hamb-line {
  background: black;
  display: block;
  height: 3px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: black;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 5px;
}

.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
} /* Hide checkbox */

/* Toggle menu icon */
.side-menu:checked ~ nav {
  max-height: 12em;
  width: 100%;
}

.side-menu:checked ~ .nav {
  max-height: 12em;
  width: 100%;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}

.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

/* Responsiveness */
@media (min-width: 768px) {
  .nav {
    max-height: none;
    top: 0;
    position: relative;
    float: right;
    width: fit-content;
    background-color: transparent;
  }

  .nav-container {
    display: flex;
    justify-content: flex-end;
    height: 4em;
  }

  .right-nav {
    margin-top: -0.6em;
  }

  .menu li {
    float: left;
  }

  .menu a:hover, .menu span:hover {
    background-color: transparent;
    color: #85888C;
    /* padding-left: 30px; */
  }

  .hamb {
    display: none;
  }

  .sign-up-form {
    width: 70%;
  }

  .banner {
    padding-left: 20%;
    padding-right: 20%;
  }

  .about-gutter {
    background-color: #D7E3FF;
  }

  .info:hover {
      background-color: #ECF2FE;
  }

  .square {
      height: 150px;
      width: 150px;
      margin-left: 10px;
      margin-bottom: 10px;
  }

  .numbers {
      color: #999999 !important;
  }

  .numbers:hover {
      color: #4391C8 !important;
  }

  header {
      position: relative !important; /* header not fixed on desktop */
  }

  footer {
      padding-left: 15% !important;
      padding-right: 15% !important;
  }

  #footer-logo {
      width: 25% !important; /* control the footer image size on desktop */
  }

  .page-head {
    margin-top: 2em;
  }

  .drop-details, .race-details {
    margin-left: 1em;
  }
}

@media (max-width: 400px) {
  .d-sm-none, .d-xs-none {
    display: none;
  }

  .upload-reminder {
    margin-left: 1em;
  }

  .search-input {
    max-width: 200px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .drop-ctr {
    height: 300px;
  }

  nav {
    flex-wrap: unset !important;
  }

  .nav {
    position: absolute;
    top: 4em;
    height: 12em;
  }

  ul {
    margin-block-start: -1em !important;
  }

  .menu li {
    height: 3em;
    margin: auto;
  }

  .search-bar {
    position: relative;
    margin-top: 4em;
  }

  .trending-info {
    /* give trending info section at top more room to breathe on mobile */
    padding-left: 2em;
  }

  .race-summary {
    margin-top: 1.5rem;
  }

  .validation-reminder {
    border-top: 1px solid black;
    width: 100% !important;
  }
}

.page-head {
  margin-top: 4em;
  margin-bottom: 1em;
}

.drop-ctr {
  border: 1px solid #808080;
  background-color: #ECF2FE;
  display: grid;
  margin-bottom: 1em;
}

.drop-elements {
  display: grid;
}

.file-drop {
  border: 1px dashed #808080;
  width: 80%;
  height: 80%;
  display: grid;
  justify-self: center;
  align-self: center;
}

.drop-details, .race-details {
  border: 1px solid #808080;
}

.drop-details > .row, .race-details > .row {
  margin-top: 1em;
}

.move-down {
  position: relative;
  bottom: -50px;
}

.race-page-headline {
  text-align: left;
  font-size: 1.8rem;
  font-weight: bold;
}

.race-name, .candidate-name {
  border-bottom: 1px solid black;
}

.upload-details {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #F6F6F6;
  padding: 1em;
}

.detail-headline {
  font-weight: bold;
}

.detail-data {
  padding-bottom: 0.8em;
  font-size: 0.8em;
}

.watch-btn {
  font-size: 0.8em;
  margin-bottom: 1em;
}

.dark-btn {
  background-color: #1D1D1D !important;
  color: #F1F1F1 !important;
}

/* .dark-btn:hover {
  fill in later?
} */

.pad-horz {
  padding-left: 1em;
  padding-right: 1em;
}

.margin-horz {
  margin-left: 1em;
  margin-right: 1em;
}

.pad-left {
  padding-left: 1em;
}

.text-left {
  text-align: left;
}

.add-btn-row {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.show-menu {
  display: block !important;
}

.dropdown-toggle {
  border: none;
}

.full-race-img {
  width: 100%;
}

.selected-img {
  display: grid;
  align-content: center;
}

.no-main-img {
  border: 1px solid black;
  border-radius: 0.5em;
}

.img-thumbs {
  margin-top: 1.5em;
}

.img-thumb {
  height: 180px;
  width: 250px;
  border-radius: 0.5em;
  overflow: hidden;
}

.img-thumb-empty {
  height: 100px;
  width: 250px;
  border-radius: 0.5em;
  display: grid;
  align-content: center;
  border: 1px dashed #808080;
}

.thumb {
  width: 100%;
}

.option-title {
  margin-bottom: 2em;
  display: flex;
  justify-content: start;
}

.input-description {
  margin-top: -1.5em;
  margin-bottom: 1em;
  text-align: left;
  justify-self: start;
  display: flex;
  padding-left: 12%;
  padding-right: 1em;
}

.upload-check {
  background-color: #F6F6F6;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.upload-opt-btn {
  background: #FFFFFF;
  border: 1px solid #808080;
  width: 60%;
}

.options {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.add-more {
  border: 1px dashed #808080;
  color: #808080;
  display: grid;
  justify-items: center;
  align-items: center;
}

.add-more, .doc-preview {
  margin-top: 1em;
  margin-right: 1em;
  height: 8em;
  border-radius: 0.3em;
  overflow: hidden;
}

.upload-preview {
  overflow: hidden;
}

.preview-img {
  height: 100%;
}

.upload-img-preview {
  max-width: 100%;
}

.add-icon {
  margin-bottom: -2.5em;
}

.add-text {
  font-size: 0.7em;
}

footer {
  background-color: #ECF2FE;
  position: relative;
  bottom: 0;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-top: 3px dashed #808080;
}

#footer-logo {
  width: 50%;
  margin-bottom: 20px;
}

footer > div {
  text-align: left;
  justify-content: start;
}

.blue-link {
  color: #3294D9;
}

.blue-link:hover {
  color: #2876AD;
}

.left-justify {
  justify-self: start;
}

header {
  background-color: #FFF;
  border-bottom: 1.5px solid #000;
  position: fixed; /* fix header/navbar to the top of the screen on mobile */
  top: 0;
  width: 100%;
  z-index: 10 !important; /* set for mobile; was disappearing behind the main body */
}

.contact div {
  padding-top: .3em;
}

.blue {
  color: #2876AD;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  cursor: pointer;
}

.underline-no-hover {
  text-decoration: underline;
}

.pad-top {
  padding-top: 1em;
}

.small-grey {
  margin-top: 10px;
  font-size: 0.6em;
  color: #808080;
}

.small {
  font-size: 0.6em;
}

.red {
  color: red;
  font-size: 0.8em;
}

.news-card {
  background: white;
}

.email-message, .sign-in-error {
  color: red;
  font-size: 0.8em;
  font-style: italic;
  justify-self: center;
}

.sign-in-main {
  padding-left: 0 !important;
  background-image: url('./img/courant-signin--bg-img.svg');
}

.sign-in-div {
  background-color: #ECF2FE;
}

.top-spacing {
  margin-top: 50px;
}

.signup {
  padding: 3em;
  background-color: #3294D9;
  color: #FFFFFF;
  width: 100%;
}

#email-input {
  min-width: 40%;
  max-width: 70%;
}

.help-banner {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #F8F8FF;
  margin-top: 2em;
  margin-bottom: 2em;
  /* padding-top: 1em;
  padding-bottom: 1em; */
}

.upload-reminder {
  border-left: 1px solid black;
  border-right: 1px solid black;
  font-size: 2.2em;
  padding-left: 1em;
  padding-right: 1em;
  display: grid;
  align-content: center;
}

.validation-reminder {
  padding: 1em;
  padding-left: 4em;
  width: 50%;
  align-items: center;
}

.validation-reminder > div {
  margin-top: 0.75em;
}

.full-height-in-div {
  height: 100% !important;
}

.trending {
  margin-top: 3em;
  margin-bottom: 3em;
}

.map-selector {
  background-color: #F8F8FF;
  border-top: 2px dashed gray;
  border-bottom: 2px dashed gray;
  padding: 3em;
}

.horizontally-center {
  margin-left: auto;
  margin-right: auto;
}

.vertically-center {
  margin-top: auto;
  margin-bottom: auto;
}

.map-race-info {
  text-align: left;
}

.map-info-headline {
  font-size: 1.7em;
  font-weight: bold;
}

.map-image {
  width: 100%;
  padding-left: 2em;
}

.map-race-headline {
  font-weight: bold;
  font-size: 1.3em;
}

.race-breakdown {
  font-weight: bold;
  font-size: 1.1em;
}

.counteract-map-padding {
  position: relative;
  left: -12px;
}

.trending-info {
  text-align: left;
}

.trending-button {
  justify-content: flex-end;
  padding-top: 1em;
}

.section-headline {
  font-weight: bold;
  font-size: 1.9em;
}

.summary-list {
  margin-bottom: 2em;
  padding-left: 2em;
}

.no-pad-left {
  padding-left: 0;
}

.mailer-thumb {
  width: 100%;
}

.race-summary {
  border: 1px solid black;
  border-radius: 2%;
  background-color: white;
  /* width: 200px; */
  transition: filter 1s;
}

.race-summary:hover {
  filter: drop-shadow(5px 5px 0.5rem #808080);
}

.summary-headline-row {
  display: inline;
  text-align: left;
}

.race-summary-headline {
  font-weight: bold;
  font-size: 1.5em;
  text-align: left;
  margin-top: 1em;
}

.lock-icon, .upgrade-btn, .summary-location {
  margin-left: 0.5em;
}

.btn {
  background-color: #E9F6FF;
  border: 1px solid black;
  height: 2em;
  width: auto;
  border-radius: 6px !important;
}

.btn-dark {
  background-color: #D3D3D3 !important;
}

.btn-light {
  background-color: white !important;
}

.bi-person-circle {
  margin-top: 4px;
}

.btn:hover {
  border: 1px solid black;
  background-color: #D1DDE5 !important;
}

.btn-dark:hover {
  background-color: #A8A8A8 !important;
}

.lock-icon {
  text-align: left;
}

.race-info {
  height: 4rem;
  border-top: 1px solid black;
}

.candidate-one, .candidate-two {
  height: 2rem;
  text-align: center;
  justify-content: center;
  border-right: 1px solid black;
}

.candidate-two {
  border-top: 1px solid black;
}

.race-year, .upload-count {
  text-align: center;
  justify-content: center;
}

.race-image-container {
  height: 7rem;
}

.race-image {
  display: grid;
  height: 100%;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  padding: 0;
}

.race-image-placeholder {
  justify-self: center;
  align-self: center;
}

.locked-img {
  /* opaque overlay on top of an image */
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  height: 7rem;
  position: relative;
  left: 0;
  top: -7rem;
}

.search-bar {
  background-color: #F0F0F0;
  border-bottom: 1px solid black;
  align-content: center;
}

.search {
  padding-top: 4px;
}

.search-input {
  width: 280px;
}

.color {
  display: flex;
  justify-content: center;
}

.color > span {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.color > span.orange {
  background-color: orange;
}

.color > span.gray {
  background-color: lightgray;
}

.race-link {
  border-left: 1px solid black;
  border-right: 1px solid black;
  width: 30%;
  font-size: 0.8em;
  background: white;
}

.candidates {
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: grid;
  text-align: center;
  align-content: center;
}

.loc {
  display: grid;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.grid-display {
  display: grid;
}

.btn-blue {
  border-radius: 0;
  background-color: #3371F2;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
}

.btn-white {
  border: 1px solid black;
    border-radius: 0;
  background-color: #FFFFFF;
  color: #3371F2;
}

.medium-font {
  font-family: 'Roboto Medium', sans-serif;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.question {
  background-color: #ECF2FE;
  color: #666666;
  padding: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.banner {
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 20%;
  padding-right: 20%;
}

#top-btn {
  margin-top: .5em;
}

.about-gutter {
  background-color: #D7E3FF;
}

.num {
  color: #CCCCCC;
}

.info-purp {
  background-color: #ECF2FE;
  padding: 1em;
}

.info {
  padding: 1em;
}

.purp-text {
  color: #3371F2;
}

.gray-text {
  color: #999999;
}

.purp {
  background-color: #90ACF7;
}

.pink {
  background-color: #ECF2FE;
}

.fluid-padded {
  padding-top: 2em;
  padding-bottom: 2em;
}

.padded {
  padding: 2em;
}

.squares {
  margin: auto;
  padding-left: 100px;
}

.square {
  height: 150px;
  width: 150px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.circle {
  background-color: #A0BCF8;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

#help {
  padding-top: 2em;
  padding-bottom: 2em;
}

.hidden {
  display: none;
}

/* image preview pagination */
.pagination-ctrl > div {
  border: 1px solid black;
  width: 26px;
  height: 26px;
  display: inline-block;
  cursor: pointer;
}

.pagination-ctrl > div.disabled {
  color: gray;
  cursor: default;
}

/* tooltips (Bootstrap attempt never worked properly) */
.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 150px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.tooltip-trigger {
  cursor: pointer;
  position: relative;
}

.tooltip-trigger:hover .tooltip-text {
  visibility: visible;
}

.tooltip-text.bottom {
  top: 25px;
  left: -50%;
}

/* upload */
#fileUpload, #moreFileUpload {
  display: none;
}

.file-upload-btn {
  background-color: #f9f9f9;
  border: 1px solid #9599A1;
  width: 80px;
  height: 30px;
  display: flex;
  justify-self: center;
}

.file-upload-btn:hover {
  cursor: pointer;
}

.how-to-upload {
  background-color: #ECF2FE;
  padding: 1em;
  margin-top: 2em;
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 100%;
}

.circ-icon {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 32px;
  width: 32px;
  border: 2px solid black;
  border-radius: 50%;
}

.center-me {
  justify-content: center;
  align-content: center;
}